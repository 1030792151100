import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import PageImage from '@/assets/new_image/categories_phone.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'mobile',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()

const areaList = ref([
  { x: 2.25, y: 0.43, width: 1.47, height: .52, href: '/' },
  { x: 3.91, y: 0.43, width: 1.47, height: .52, href: '/detail-rule' },
  { x: 5.58, y: 0.45, width: 1.65, height: .52, href: '/rule' },
  //
])

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(PageImage),
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`/attend?t=2`))),
      class: "absolute top-[1.97rem] left-[2.46rem] text-[.12rem] w-[4.1rem] h-[4.1rem] rounded-[50%] cursor-pointer flex flex-col justify-center align-center text-[#fff] text-center"
    }),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push(`/attend?t=1`))),
      class: "absolute top-[6.2rem] left-[.3rem] text-[.12rem] w-[4.1rem] h-[4.1rem] rounded-[50%] cursor-pointer flex flex-col justify-center align-center text-[#fff] text-center"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, ({ x, y, width, height, href }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_unref(router).push(href)),
        class: "absolute cursor-pointer",
        style: _normalizeStyle({ left: x + 'rem', top: y + 'rem', width: width + 'rem', height: height + 'rem' })
      }, null, 12, _hoisted_3))
    }), 128))
  ]))
}
}

})